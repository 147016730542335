import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Mental well-being services are committed to confidential, non-judgmental and compassionate care to adults, adolescents, couples and elderly through Professional Counselling, Training Programs and Psychotherapy.`}</p>
    <h2>{`COUNSELLING SERVICE`}</h2>
    <p>{`Provides insight and guidance in managing life issues.`}</p>
    <p>{`Psychotherapy supports cure of psychosomatic problems and deep seated emotional issues.`}</p>
    <h2>{`TRAINING PROGRAMS`}</h2>
    <p>{`Focus on personality enhancement and professional excellence through emotional empowerment, education and behavioral skill development.`}</p>
    <h2>{`PSYCHOTHERAPY`}</h2>
    <p>{`Assist to optimize personal excellence, relationships and self-esteem.`}</p>
    <p>{`Face to face, telephonic or Skype`}</p>
    <h2>{`Complete list of services`}</h2>
    <h3>{`Hypnotherapy`}</h3>
    <p>{`It is a 'Mind-Body' modality health practice for curing emotional issues of the past and present to enhance personal life with immediate effect, opening up most pleasant cure for body-mind-emotional problems.`}</p>
    <h3>{`Individual Counselling and Psychotherapy`}</h3>
    <p>{`This is a one-to-one process with the psychologist for managing life issues and related crippling feelings through professional advice and guidance.`}</p>
    <h3>{`Psychosomatic Cure`}</h3>
    <p>{`Relationship with mind and body is so strong that they affect each other and cause psycho-physical issues like migraine, back ache,  neck pain, ulcers, allergies, nausea etc., leaving persons to tremendous stress. We can cure them!`}</p>
    <h3>{`Psychotherapy for depression, Anxiety and Phobias`}</h3>
    <p>{`Most of our Anxiety disorders have a root and a history that need to be revisited and healed. For example, phobia is a gripping fear when the person is exposed to the object of fear experienced  for months or years. It is possible to cure them fully through psychotherapy.`}</p>
    <p>{`​`}</p>
    <h3>{`Couple & Relationship Counselling`}</h3>
    <p>{`Provides support to parties in a committed or intimate relationship to figure out and manage bond damaging issues through relationship skill development and reconciliation.`}</p>
    <p>{`​`}</p>
    <h3>{`Relationship issues (Romance, Friendship, Peer, Colleagues etc.)`}</h3>
    <p>{`Relationship is close to the heart and that which affects relationship directly affects the heart to overturn our life. It is very much possible to solve and manage them.`}</p>
    <h3>{`Behavioral management training for children and adolescents`}</h3>
    <p>{`Youngsters often do not notice age related behavioral problems or have no strength and ideas to overcome them. We provide service for identification and ego strengthening behavioral training in the  timely management of behavioral issues of children and adolescents.`}</p>
    <p>{`​`}</p>
    <h3>{`Parenting Support`}</h3>
    <p>{`Roller coaster role of parenting leaves parents exhausted wanting in ideas to manage their kids at times even damaging their own relationship with each other. We are a support you can reach out to in this scenario.`}</p>
    <h3>{`Smoking cessation and de-addiction support`}</h3>
    <p>{`Smoking and addictive habits have a psycho-social history. We help the persons through therapeutic assistance to overcome these habits in a three dimensional approach.`}</p>
    <p>{`​`}</p>
    <h3>{`Stress Management`}</h3>
    <p>{`Stress impede everyday functioning. Stress management is a tricky skill as no stress is same in degree and fashion. We provide customized spectrum of techniques and therapies to manage stress.`}</p>
    <h3>{`Post Traumatic Stress treatment`}</h3>
    <p>{`Getting out of shock and trauma after a tragedy, abuse or other very disturbing experience is hard. Post Traumatic Stress treatment heals the person to get back to normal life.`}</p>
    <h3>{`Emotional healing`}</h3>
    <p>{`When unidentified emotions mismanage daily life it is important to identify and manage them before it derails our whole life. Emotional healing does identify and help you healed of the past.`}</p>
    <h3>{`Grief Therapy`}</h3>
    <p>{`Major life changes and deaths of loved ones or pets leave persons in deep grief and it is the right moment to reach out and overcome it before life begin to get out of control.`}</p>
    <h3>{`Work-Life Balance`}</h3>
    <p>{`Work, family commitments and personal life is often pushed out of balance leading to fatigue and burnout that affect profession and relationships.  Work-life Balance is a skill we teach to empower persons through training and professional counselling.`}</p>
    <p>{`​`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      